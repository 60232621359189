<template>
  <el-dialog
      v-model="isShow"
      title="新增节点模版"
      width="30%"
      :before-close="handleClose"
  >
    <el-form ref="formRef" :inline="false" :model="formState" :rules="rules">
      <el-form-item label="模版名称" prop="name">
        <el-input v-model="formState.name" maxlength="10" placeholder="请输入模版名称"/>
      </el-form-item>
      <el-form-item label="项目类型" prop="projectTypeId">
        <el-select v-model="formState.projectTypeId" style="width:100%" filterable clearable
                   placeholder="请选择项目类型">
          <el-option
              v-for="item in productTypeOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleSubmit" :disabled="submitting">确定</el-button>
          </span>
    </template>
  </el-dialog>
</template>

<script>
import {addNodeTemplate} from '@/api/node_template'
import {isResOK} from '@/api/response'
import {getProjectTypeOptions} from "@/api/project_type";

export default {
  name: 'NewNodeTemplate',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:visible', 'completed'],

  data() {
    return {
      formState: {
        name: null,
        projectTypeId: null,
      },

      submitting: false,
      rules: {
        name: [
          {required: true, message: "模版名称不能为空", trigger: "blur"}
        ],
        projectTypeId: [
          {required: true, message: "项目类型不能为空", trigger: "blur"}
        ],
      },
      productTypeOptions: [],
    }
  },

  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },

  created() {
    this.getProductTypeOptions()
  },

  methods: {
    handleSubmit() {
      if (this.submitting) {
        this.$message({
          message: '已经提交，无需重复提交',
          type: 'warning',
        })
        return
      }

      this.submitting = true
      this.$refs.formRef.validate((values, fields) => {
        if (values) {
          addNodeTemplate(Object.assign({}, this.formState, { operatingMode: 'web' })).then((res) => {
            if (isResOK(res)) {
              this.$message({
                message: '数据添加成功',
                type: 'success',
              })
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          }).finally(() => {
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
      this.getProductTypeOptions()
    },
    handleClose() {
      this.isShow = false
    },
    getProductTypeOptions() {
      getProjectTypeOptions().then(res => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data
        }
      })
    },
  },
}
</script>
